import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useEffect, useRef, useState } from 'react';
import Button from '../../components/Button';
import Result from '../../components/Result';
import SEO from '../../components/SEO';
import { checkStatus } from '../../services';
import { CheckStatusBody } from '../../services/interface';

import * as styles from './index.module.less';

type ActiveType = CheckStatusBody['type'];
const STEP_LIST = [
  {
    cname: '空间检查',
    type: 1,
  },
  {
    cname: '资源检查',
    type: 2,
  },
  {
    cname: '启动中',
    type: 3,
  },
];

function useCheckStatus(user_name: string, setActive: (active: ActiveType) => void) {
  const active = useRef<ActiveType>(1);
  const [status, setStatus] = useState<null | 'success' | 'error'>(null);
  const [errMsg, setErrMsg] = useState<string>('');
  function checkStatusHandler() {
    if (!user_name) return;

    checkStatus({ user_name, type: active.current })
      .then(({ data }) => {
        if (data.data === true) {
          active.current += 1;
          setActive(active.current as ActiveType);
          if (active.current > 3) {
            setStatus('success');
            setErrMsg('租户启动完成');
            return;
          }
        }
        setTimeout(() => {
          checkStatusHandler();
        }, 500);
      })
      .catch((e) => {
        setStatus('error');
        setErrMsg(e.data?.data?.msg || '检查失败');
      });
  }

  function init() {
    active.current = 1;
    setStatus(null);
    setErrMsg('');
    checkStatusHandler();
  }

  useEffect(() => {
    init();
  }, []);

  return {
    status,
    errMsg,
    init,
  };
}

export default function RegisterProgressPage({ location }: { location: Location }) {
  const [active, setActive] = useState<ActiveType>(1);
  const query = new URLSearchParams(location.search);
  const { status, errMsg, init } = useCheckStatus(query.get('username') || '', setActive);

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        {!status ? (
          <>
            <StaticImage
              height={140}
              src="../../assets/images/logo.png"
              alt=""
              placeholder="none"
            />
            <div className={styles.title}>租户初始化中, 请稍后</div>
            <div className={styles.steps}>
              {STEP_LIST.map((item) => {
                const cls = [
                  styles.item,
                  active >= item.type && styles.active,
                  active === item.type && styles.itemLoading,
                ]
                  .filter((i) => !!i)
                  .join(' ');
                return (
                  <div key={item.type} className={cls}>
                    <div>{item.cname}</div>
                  </div>
                );
              })}
            </div>
            <div className={styles.goHome}>
              <Link to="/">返回首页</Link>
            </div>
          </>
        ) : (
          <Result type={status} title={errMsg}>
            <div style={{ marginTop: 20 }}>
              <Link to="/" style={{ color: '#333', marginRight: 15 }}>
                <Button type="outline">回首页</Button>
              </Link>
              {status === 'success' ? (
                <Link to={`/login?username=${query.get('username')}`}>
                  <Button type="primary">登 录</Button>
                </Link>
              ) : (
                <Button type="primary" onClick={init}>
                  重 试
                </Button>
              )}
            </div>
          </Result>
        )}
      </div>
    </div>
  );
}

export function Head() {
  return <SEO title="租户初始化" />;
}
